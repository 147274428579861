let isProd = false;
export const API_URL = isProd ? "https://stagingapi.sfrglobal.com" : "https://stagingapi.sfrglobal.com";
export const GRAPHQL_URL = API_URL + "/graphql";
export const imageDownloadUrl = "https://cdn.sfrglobal.com";
export const imageUploadUrl = `${API_URL}/api/S3Containers/staging.storage.sportsforce/upload`;
export const videoUploadUrl = `${API_URL}/upload`;
export const zapierApiCall = 'https://hooks.zapier.com/hooks/catch/15010376/3u6s3zw/'
export const apiKey = "AIzaSyD4pmDReyz6px3lTCv1S-p9HZU1ccDg7-I&v=3.exp";
export const mapApiJs = "https://maps.googleapis.com/maps/api/js";
export const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";
export const prod = isProd ? "https://staging.sfrglobal.com/" : "https://staging.sfrglobal.com/";
export const websiteDefaultUrl = isProd ? "https://staging.sfrglobal.com/" : "https://staging.sfrglobal.com/";
export const heapToken = process.env.REACT_APP_heapToken;
export const awsRegion = process.env.REACT_APP_AWS_REGION;
export const awsVideoSourceAccessKeyId = process.env.REACT_APP_S3_VIDEO_SOURCE_ACCESS_KEY_ID;
export const awsVideoSourceSecretAccessKey = process.env.REACT_APP_S3_VIDEO_SOURCE_SECRET_ACCESS_KEY;
export const awsVideoSourceBucket = process.env.REACT_APP_S3_VIDEO_SOURCE_BUCKET;